import React from 'react'
import ProductGridView from '../product-view/product-grid-view'
import { Helmet } from 'react-helmet'
import { useCompanyConfig } from '..//..//fetch-data/config'
import Spinner from '../utils/spinner/full-page-spinner'
function Home() {
  const { data, isLoading, error, refetch } = useCompanyConfig();
  if(isLoading){
    return <Spinner/>
  }
  return (
    <div className="main home demo2-cls">
      {
        !isLoading ? <>
          <Helmet>
            <title>{data.companyName} - Home</title>
            <link rel="icon" type="image/png" href={data.favIcon} />
          </Helmet>
          <div className="page-content">
            <div className="container">
              {/* <ProductGridView collectionSlug={{ "collectionSlug": '50-50-lotto' }} loading={false} /> */}
              <ProductGridView collectionSlug={{ "collectionSlug": `${data.homepageCollection}` }} loading={false} />
            </div>
          </div>
        </> : <Spinner/>
      }


    </div>
  )
}

export default Home
