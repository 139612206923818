import React, { useState } from 'react';
import ALink from '..//utils/alink'
import { toDecimal, getTotalPrice } from "..//utils";
import { connect } from "react-redux";
import { Helmet } from 'react-helmet';
import { useCompanyConfig } from '../../fetch-data/config';
import Spinner from '../utils/spinner/full-page-spinner';
import axios from 'axios';
const Checkout = (props) => {
    const { data, isLoading, error, refetch } = useCompanyConfig();
    const [pageSpinner , setPageSpinner] = useState(false);
    const { cartList } = props;
    const handlePlaceOrderSubmit = async (event) => {
        event.preventDefault();
        setPageSpinner(true);
        let checkoutURL = await generateStripeCheckoutURL();
        setPageSpinner(false);
        if(checkoutURL.length>0){
            window.location.href = checkoutURL;
        }
        console.log('Checkout===>',checkoutURL);
    };
    const generateStripeCheckoutURL = async () => {
        const response = await axios.post(
            'https://smzk5bfw92.execute-api.eu-west-1.amazonaws.com/v1/pipeline/execute',
            {
                pipelineName: "CreateCheckOutSession",
                pipelineParams: [
                    { "name": "cartItems", "value": getCartItem() },
                    { "name": "checkoutFormDetails", "value": getFormDetails() },
                    { "name": "successOrigin", "value": window.location.origin },
                    { "name": "cancelOrigin", "value": window.location.origin }
                ]
            },
            {
                headers: {
                    'x-tenant-id': data.tenant_id,
                },
            }
        );
        return response.data.CheckOutSessionUrL;
    }
    const getCartItem = () => {
        console.log('CartList===>', JSON.stringify(cartList));
        return JSON.stringify(cartList);
    }
    const getFormDetails = () => {
        const formElement = document.getElementById('çheckOutForm');
        const formData = new FormData(formElement);
        console.log('formDetails==>', JSON.stringify(Object.fromEntries(formData.entries())))
        return JSON.stringify(Object.fromEntries(formData.entries()));
    }
    const resetFormData = () => {
        const formElement = document.getElementById('çheckOutForm');
        formElement.reset();
    }
    if (isLoading || pageSpinner) {
        return <Spinner />;
    }
    return (
        <>
            <Helmet>
                <title>{data.companyName} | Checkout</title>
                <link rel="icon" type="image/png" href={data.favIcon} />
            </Helmet>
            <div className=' container mt-7 page-content pt-2 pb-1  checkout mb-2'>
                {
                    cartList.length > 0 ?
                        <form action="#" className="form" onSubmit={handlePlaceOrderSubmit} id='çheckOutForm'>
                            <div className="row">
                                <div className="col-lg-7 mb-6 mb-lg-0 pr-lg-4">
                                    <div className="row mb-2">
                                        <label htmlFor='firstName'>First Name *</label>
                                        <input className={`form-control`} name='firstName' id='firstName' type='text' required={true} placeholder='' />
                                    </div>
                                    <div className="row mb-2">
                                        <label htmlFor='lastName'>Last Name *</label>
                                        <input className={`form-control`} name='lastName' id='lastName' type='text' required={true} placeholder='' />
                                    </div>
                                    <div className="row mb-2">
                                        <label htmlFor='email'>Email *</label>
                                        <input className={`form-control`} name='email' id='email' type='email' required={true} placeholder='' />
                                    </div>
                                    <div className="row mb-2">
                                        <label htmlFor='phone'>Phone *</label>
                                        <input className={`form-control`} name='phone' id='phone' type='number' required={true} placeholder='' />
                                    </div>
                                </div>
                                <aside className="col-lg-5 sticky-sidebar-wrapper">
                                    <div
                                        className="sticky-sidebar mt-1"
                                        data-sticky-options="{'bottom': 50}"
                                    >
                                        <div className="summary pt-5" >
                                            <table>
                                                <tr>
                                                    <td> <h3 className="title title-simple text-left text-uppercase">Total</h3></td>
                                                    <td><h3 className="title title-simple text-left text-uppercase" style={{ textAlignLast: "right" }}>{data.currency}{toDecimal(getTotalPrice(cartList))}</h3></td>

                                                </tr>
                                            </table>
                                            <div className="mb-3">
                                                <button type="submit" className={`btn btn-dark btn-rounded btn-order`}>
                                                    Place Order
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </aside>
                            </div>
                        </form >
                        : <>
                            <div className="empty-cart text-center">
                                <p>No item for checkout.</p>
                                <i className="cart-empty d-icon-bag"></i>
                                <p className="return-to-shop mb-0">
                                    <ALink
                                        className="button wc-backward btn btn-dark btn-md"
                                        href="/"
                                    >
                                        Return to shop
                                    </ALink>
                                </p>
                            </div>
                        </>
                }

            </div >
        </>
    );
};
function mapStateToProps(state) {
    return {
        cartList: state.cart.data ? state.cart.data : [],
    };
}

export default connect(mapStateToProps)(Checkout);
