import React, { useEffect } from 'react';
import Helmet from 'react-helmet';

import ALink from '../components/utils/alink';
import { parallaxHandler } from '../components/utils';
import { useCompanyConfig } from '..//fetch-data/config'
import Spinner from '../components/utils/spinner/full-page-spinner';
function Error404 () {
    const { data, isLoading, error } = useCompanyConfig();
    useEffect( () => {
        window.addEventListener( 'scroll', parallaxHandler, true );

        return () => {
            window.removeEventListener( 'scroll', parallaxHandler, true );
        }
    }, [] )
    if(isLoading){
        return <Spinner/>
    }
    return (
        <main className="main">
            <Helmet>
                <title>{data.companyName} | 404</title>
                <link rel="icon" type="image/png" href={data.favIcon} />
            </Helmet>

            <h1 className="d-none"> - 404</h1>

            <div className="page-content">
                <section
                    className="error-section d-flex flex-column justify-content-center align-items-center text-center pl-3 pr-3">
                    <h1 className="mb-2 ls-m">Error 404</h1>
                    <img src={data.pageNotFound.src} alt={data.pageNotFound.alt} width={data.pageNotFound.width} height={data.pageNotFound.height} />
                    <h4 className="mt-7 mb-0 ls-m text-uppercase">Ooopps! That page can’t be found.</h4>
                    <p className="text-grey font-primary ls-m">It looks like nothing was found at this location.</p>
                    <ALink href="/" className="btn btn-primary btn-rounded mb-4">Go home</ALink>
                </section>
            </div>
        </main >
    )
}

export default React.memo( Error404 );
